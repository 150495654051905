export interface HealthProfessionalName {
  firstName: string;
  lastName: string;
  prefix?: string | null;
  suffix?: string | null;
}

function isEmptyString(value?: string | null): boolean {
  return value === undefined || value === null || value.trim() === "";
}

export const getHealthProfessionalFullName = (healthProfessional: HealthProfessionalName) => {
  const nameData = [healthProfessional.prefix, healthProfessional.firstName, healthProfessional.lastName];

  let name = nameData.filter((item) => !isEmptyString(item)).join(" ");

  const suffix = healthProfessional.suffix?.trim();
  name += suffix ? `, ${suffix}` : "";

  return name;
};
